import img01 from "../assets/images/clientes/img01.png"
import img02 from "../assets/images/clientes/img02.png"
import img03 from "../assets/images/clientes/img03.png"
import img04 from "../assets/images/clientes/img04.png"
import img05 from "../assets/images/clientes/img06.png"
import img06 from "../assets/images/clientes/img07.png"
import img07 from "../assets/images/clientes/img08.png"
import img08 from "../assets/images/clientes/img09.png"
import img09 from "../assets/images/clientes/img10.jpeg"
import img10 from "../assets/images/clientes/img11.jpg"
import img11 from "../assets/images/clientes/img12.jpg"
import img12 from "../assets/images/clientes/img13.jpg"
export default [
  {
    img: img01,
  },
  {
    img: img02,
  },
  {
    img: img03,
  },
  {
    img: img04,
  },
  {
    img: img05,
  },
  {
    img: img06,
  },
  {
    img: img07,
  },
  {
    img: img08,
  },
  {
    img: img09,
  },
  {
    img: img10,
  },
  {
    img: img11,
  },
  {
    img: img12,
  },
]
